body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  background-color: transparent !important;
  margin-top: 6%;
  float: right;
}

.navbar-brand section {
  margin: 0 0 0 22%;
}

.title {
  position: relative;
}

.title h2 {
  color: #fff;
  font-size: 4em;
  position: absolute;
  transform: translate(-50%, -50%);
}

.title h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px darkslategrey;
}

.title h2:nth-child(2) {
  color: slategrey;
  animation: animate 4s ease-in-out infinite;
}

@keyframes animate {

  0%,
  100% {
    clip-path: polygon(0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%);
  }

  50% {
    clip-path: polygon(0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%);
  }
}

.nav-link {
  font-size: 1.3em;
  font-stretch: expanded;
  font-family: fantasy;
  color: darkmagenta;
}

.basicinfo {
  margin-top: 3%;
  font-size: 1.2em;
  font-family: auto;
  color: darkslategray;
  display: inline-block
}

.hero {
  position: relative;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 3% 5% 2% 5%;
}

body::before {
  content: "";
  background-image: url('images/background1.jpg');
  background-size: contain;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.15;
}

.line {
  height: 3px;
  background: linear-gradient(to right, darkgoldenrod, blue, hotpink, lightgoldenrodyellow, hotpink, blue, darkgoldenrod);
  -webkit-filter: blur(1.5px);
  border-radius: 5px;
}

.line-1 {
  margin: 1% 10% 1% 10%;
}

.line-2 {
  margin: 0% 10% 1% 10%;
}

.line-3 {
  margin: 0% 10% 7% 10%;
}

.about {
  margin-bottom: 5%;
}

.about div {
  display: inline-block;
  width: 50%;
}

.about img {
  display: inline-block;
  width: 50%;
}

.featureline {
  height: 3px;
  background: coral;
  border-radius: 5px;
}
.figures .figurescount{
  font-size: 2.5rem;
  color: cadetblue;
  display: inline-block;
  font-weight: bold;
}
.figurestitle{
  color: black;
  font-size: 1.2rem;
  font-weight: normal;
}
.certificates,.financialstatements{
  margin-top: 8%;
  padding: 20px;
  text-align: center;
}
.certificates img,.financialstatements img{
  display: inline-block;
  height: 350px;
  width: 250px;
  border: 1px solid;
  margin: 20px 0 15px 0;
  cursor: pointer;
  box-shadow: 10px 10px 10px darkslategrey;
}
.certificates p,.financialstatements p{
  font-size: 1.2rem;
}
.btn-primary{
  background-color: cadetblue;
  cursor: pointer;
}
.sustanability, .aboutus,.productsdetail{
  margin: 8% 0 0 0;
}